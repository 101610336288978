import React from "react";
import { graphql } from "gatsby";

import ComponentsType from "@views/ComponentsType/ComponentsType";
import Layout from "../ui/layout";

interface ComponentTypePageProps {}

const ComponentTypePage: React.FC<ComponentTypePageProps> = props => {
  return (
    <Layout
      header={{ color: "black" }}
      seo={{
        title: `Free components designs For Inspiration (with React.js implementation)`,
        description: `A variety of react components designs that will help boost your inspiration. Find creative designs that are implemented in react and include them now in your project for free!`,
      }}
    ></Layout>
  );
};

export default ComponentTypePage;
